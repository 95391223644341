<template>
  <div>
    <vx-card no-shadow>
      <h2 class="mb-5">
        {{ $t('CardTitle') }}
      </h2>

      <complete-activation-steps
        :title="$t('CompleteSecurityStepsTitle')"
        :subtitle="$t('CompleteSecurityStepsSubtitle')"
        :chart-title="$t('CompleteSecurityStepsChartTitle')"
        :steps="activationsStepsMapped"
        no-card
        @google="scrollInto('google')"
        @2fa="scrollInto('2fa')"/>
    </vx-card>

    <vx-card
      no-shadow
      class="mt-base">
      <div class="vx-row mb-base pb-base">
        <div class="vx-col w-full lg:w-1/2 mt-base pt-base lg:mt-0 lg:pt-0">
          <user-account-profile-password/>
        </div>
      </div>

      <div class="vx-row mb-base pb-base">
        <div class="vx-col w-full lg:w-1/2">
          <user-account-profile-security ref="2fa"/>
        </div>
        <div class="vx-col w-full lg:w-1/2 justify-start mt-base pt-base lg:mt-0 lg:pt-0">
          <user-account-profile-accounts ref="google"/>
        </div>
      </div>
    </vx-card>
  </div>

</template>

<script>
import { mapGetters } from 'vuex';

// Components
import CompleteActivationSteps from '@/views/_components/CompleteActivationSteps.vue';
import UserAccountProfilePassword from '@/views/modules/user/user-account/user-account-profile/UserAccountProfilePassword.vue';
import UserAccountProfileSecurity from '@/views/modules/user/user-account/user-account-profile/UserAccountProfileSecurity.vue';
import UserAccountProfileAccounts from '@/views/modules/user/user-account/user-account-profile/UserAccountProfileAccounts.vue';

/**
 * User account security recommendations
 *
 * @module views/modules/user-account/UserAccountSecurity.vue
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {Object[]} [recommendationsSteps=[...]] - recommendations steps to show
 * @vue-computed {Object[]} [activationsStepsMapped=[...]] - recommendations steps to show mapped
 */
export default {
  name: 'UserAccountSecurity',
  i18n: {
    messages: {
      en: {
        CardTitle: 'Keep your account safe',
        CompleteSecurityStepsTitle: 'Security recommendations',
        CompleteSecurityStepsSubtitle: 'You can take the following security recommendations to protect your account.',
        CompleteSecurityStepsChartTitle: 'Security steps',
        $SecuritySteps: {
          $UseGoogleAccount: {
            Title: 'Use your Google account to sign in',
            Subtitle: 'Use Google’s suspicious event detection to help keep you safer online.',
          },
          $EnabledTwoFactorAuth: {
            Title: 'Enable two-factor authentication',
            Subtitle: 'Two-factor authentication (2FA) adds extra security steps to logging in. This way, you\'re safe even if others get your password.',
          },
        },
      },
    },
  },
  components: {
    CompleteActivationSteps,
    UserAccountProfilePassword,
    UserAccountProfileSecurity,
    UserAccountProfileAccounts,
  },
  data() {
    return {
      recommendationsSteps: [
        {
          title: this.$t('$SecuritySteps.$UseGoogleAccount.Title'),
          subtitle: this.$t('$SecuritySteps.$UseGoogleAccount.Subtitle'),
          event: 'google',
          icon: 'icon-lock',
          completed: false,
        },
        {
          title: this.$t('$SecuritySteps.$EnabledTwoFactorAuth.Title'),
          subtitle: this.$t('$SecuritySteps.$EnabledTwoFactorAuth.Subtitle'),
          event: '2fa',
          icon: 'icon-shield',
          completed: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getUserFlag: 'auth/getUserFlag',
      userHasGoogleEmail: 'auth/hasGoogleEmail',
    }),
    activationsStepsMapped() {
      const recommendationsSteps = [...this.recommendationsSteps];

      recommendationsSteps[0].completed = this.userHasGoogleEmail;
      recommendationsSteps[1].completed = this.getUserFlag('twoFactorAuthentication');

      return recommendationsSteps;
    },
  },
  created() {
    window.history.replaceState({}, null, '/account/security');
  },
  methods: {
    scrollInto(el) {
      this.$refs[el].$el.scrollIntoView({ behavior: 'smooth' });
    },
  },
};
</script>
