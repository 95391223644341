<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <div class="flex justify-between flex-wrap">
        <h2>
          {{ $t('Title') }}
        </h2>

        <vs-chip
          :color="userHas2faEnabled ? 'success' : 'danger'">
          <feather-icon
            :icon="userHas2faEnabled? 'CheckIcon' : 'XCircleIcon'"
            svgClasses="h-5 w-5 mr-1" />
          <span>
            {{ $t(`$General.${userHas2faEnabled ? 'Enabled': 'Disabled'}`) }}
          </span>
        </vs-chip>
      </div>

      <p class="mt-5">
        {{ $t('Description') }}
      </p>

      <div class="mt-3">
        <vs-button
          v-if="!userHas2faEnabled"
          @click="showModal2FA=true"
          color="primary"
          type="filled">
          {{ $t('$General.Enable') }}
        </vs-button>

        <template v-else>
          <div class="mb-2 font-black">
            {{ disabledTwoFactorAuthMsg }}
            {{ $t('Disable2FARequestEmail.BeforeLink') }}
            <a @click.prevent="sendEmailCodeToDisable2FA()">{{ $t('$General.Here') | lowercase }} </a>
            {{ $t('Disable2FARequestEmail.AfterLink') }}
          </div>

          <vs-input
            v-model="codeDisabledTwoFactor"
            ref="codeDisabledTwoFactor"
            class="w-full"
            :name="disabledTwoFactorAuthInputName"
            :type="userHasPassword ? 'password' : 'text'"
            v-validate="userHasPassword ? 'required|password' : 'required|min:6'"
            icon-no-border
            icon="icon icon-lock"
            icon-pack="feather"
            :danger="errors.has(disabledTwoFactorAuthInputName) || !!invalidCodeError"
            :danger-text="errors.first(disabledTwoFactorAuthInputName) || invalidCodeError"
            val-icon-danger="clear"
            @input="invalidCodeError = ''">
          </vs-input>

          <div v-if="userHasPassword">
            <forgot-password-link/>
          </div>
          <div class="mt-3">
            <vs-button
              id="user-account-profile-disable-2FA-button"
              ref="disabledButton"
              :disabled="!disabled2FAIsValid"
              color="danger"
              type="filled"
              class="mt-2 vs-con-loading__container"
              @click="confirmDisable2FA()">
              {{ $t('$General.Disable') }}
            </vs-button>

            <vs-button
              v-show="showCancel"
              class="ml-3"
              type="filled"
              color="gray"
              @click="cancelDisabled2FA()">
              {{ $t('$General.Cancel') }}
            </vs-button>
          </div>
        </template>
      </div>

      <vs-popup
        :title="$t('Setup2FAModalTitle')"
        :active.sync="showModal2FA">
        <transition name="zoom-fade">
          <setup-two-factor-authentication
            v-if="showModal2FA"
            @enabled="twoFactorAuthWasEnabled()"/>
        </transition>
      </vs-popup>

      <vs-popup
        :title="$t('Disable2FAByEmailModalTitle')"
        :active.sync="showModalDisable2FA">
        <transition name="zoom-fade">
          <disable-two-factor-authentication
            v-if="showModalDisable2FA"
            @close="showModalDisable2FA=false">
          </disable-two-factor-authentication>
        </transition>
      </vs-popup>

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

// Components
import UserAccountProfileSecurity2FASetup from '@/views/modules/user/user-account/user-account-profile/UserAccountProfileSecurity2FASetup.vue';
import UserAccountProfileSecurity2FADisable from '@/views/modules/user/user-account/user-account-profile/UserAccountProfileSecurity2FADisable.vue';
import ForgotPasswordLink from '@/views/modules/_components/ForgotPasswordLink.vue';

// Services
import userService from '@/api/modules/user.service';

/**
 * Component to allow the user manage the 2FA
 *
 * @module views/modules/user-account/UserAccountProfileSecurity
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {boolean} showModal2FA - indicate if show or no modal for setup 2FA
 * @vue-data {string} codeDisabledTwoFactor - code for disable 2FA
 * @vue-computed {boolean} disabled2FAIsValid - indicate if 2FA is valid for disabled
 * @vue-computed {boolean} showCancel - indicate if show cancel button or no
 * @vue-computed {boolean} userHas2faEnabled - indicate if user has enabled 2FA
 * @vue-computed {boolean} userHasPassword - indicate if user has password
 * @vue-computed {string} disabledTwoFactorAuthMsg - msg for disabled 2FA
 * @vue-computed {string} disabledTwoFactorAuthInputName - input name for disabled 2FA
 * @vue-event {void} cancelDisabled2FA - called for cancel the disable of 2FA
 * @vue-event {void} disable2FA - call to disable the 2FA
 */
export default {
  name: 'UserAccountProfileSecurity',
  i18n: {
    messages: {
      en: {
        Title: 'Two-factor authentication',
        Description: 'Two-factor authentication is a security process that requires two forms of user ID to log in. It helps keep your account safe. You can use an authenticator app to enable it.',
        Disabled2faAskPasswordMsg: 'Enter your current password',
        Disabled2faAskTwoFactorCodeMsg: 'Enter the code of your authentication app, your backup code',
        Disabled2faAskPasswordInputName: 'Current password',
        Disabled2faAskTwoFactorCodeInputName: '(Code / backup code) of your 2FA app',
        Setup2FAModalTitle: 'Configure Authenticator App',
        TwoFactorAuthDisabledNotifyTitle: 'Two-factor authentication',
        TwoFactorAuthDisabledNotifyMsg: 'Two-factor authentication disabled successfully',
        Disable2FAByEmailModalTitle: 'Disable two-factor authentication',
        Disable2FAConfirmDialog: {
          Title: 'Disable two-factor authentication',
          Msg: 'If you disable the two-factor authentication, you\'ll only need your email and password or your Google account when you sign in to the GrapeSend',
        },
        Disable2FARequestEmail: {
          BeforeLink: 'or request a code to your email',
          AfterLink: 'to disable the two-factor authentication',
        },
      },
    },
  },
  components: {
    SetupTwoFactorAuthentication: UserAccountProfileSecurity2FASetup,
    DisableTwoFactorAuthentication: UserAccountProfileSecurity2FADisable,
    ForgotPasswordLink,
  },
  data() {
    return {
      showModal2FA: false,
      codeDisabledTwoFactor: '',
      invalidCodeError: '',
      showModalDisable2FA: false,
    };
  },
  computed: {
    ...mapGetters({
      getUserFlag: 'auth/getUserFlag',
    }),
    disabled2FAIsValid() {
      return !this.errors.any()
        && this.codeDisabledTwoFactor !== '';
    },
    showCancel() {
      return Object.keys(this.validationFields).length > 0
        ? Object.keys(this.validationFields).some(
          (key) => this.validationFields[key].changed,
        )
        : false;
    },
    userHas2faEnabled() {
      return this.getUserFlag('twoFactorAuthentication');
    },
    userHasPassword() {
      return this.getUserFlag('hasPassword');
    },
    disabledTwoFactorAuthMsg() {
      const key = this.userHasPassword
        ? 'Disabled2faAskPasswordMsg'
        : 'Disabled2faAskTwoFactorCodeMsg';

      return this.$t(key);
    },
    disabledTwoFactorAuthInputName() {
      const key = this.userHasPassword
        ? 'Disabled2faAskPasswordInputName'
        : 'Disabled2faAskTwoFactorCodeInputName';

      return this.$t(key);
    },
  },
  methods: {
    ...mapActions({
      disableTwoFactorAuthentication: 'auth/disableTwoFactorAuthentication',
    }),
    cancelDisabled2FA() {
      this.codeDisabledTwoFactor = '';
      this.invalidCodeError = '';
      this.$validator.reset();
    },
    confirmDisable2FA() {
      this.$showConfirmDeleteDialog({
        title: this.$t('Disable2FAConfirmDialog.Title'),
        text: this.$t('Disable2FAConfirmDialog.Msg'),
        accept: () => this.disable2FA(),
        acceptText: this.$t('$General.Confirm'),
        cancelText: this.$t('$General.Cancel'),
      });
      this.$refs.disabledButton.$el.blur();
    },
    async disable2FA() {
      const formIsValid = await this.$validator.validate();

      if (!formIsValid) {
        return;
      }

      this.$vs.loading({
        background: 'danger',
        color: 'white',
        container: '#user-account-profile-disable-2FA-button',
        scale: 0.45,
      });

      try {
        await this.disableTwoFactorAuthentication(this.codeDisabledTwoFactor);

        this.$showSuccessActionNotification({
          title: this.$t('TwoFactorAuthDisabledNotifyTitle'),
          text: this.$t('TwoFactorAuthDisabledNotifyMsg'),
        });
      } catch (err) {
        if ((err.response.data.statusCode === 400 || err.response.data.statusCode === 401)
          && (err.response.data.wrongPassword || err.response.data.invalidCode)) {
          this.invalidCodeError = err.response.data.message;
        }
      } finally {
        this.$vs.loading.close('#user-account-profile-disable-2FA-button > .con-vs-loading');
      }
    },
    twoFactorAuthWasEnabled() {
      this.showModal2FA = false;
      this.codeDisabledTwoFactor = '';
      this.$validator.reset();
    },
    async sendEmailCodeToDisable2FA() {
      this.showModalDisable2FA = true;
      await userService.sentEmailCodeToDisabled2FA();
    },
  },
};
</script>
