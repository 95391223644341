<template>
  <div>
    <vs-alert
      color="success"
      icon-pack="feather"
      icon="icon-mail"
      :active="!invalidCodeError">
      <p>{{ $t('VerificationAlertMsg') }}
        <a
          href="#"
          class="ml-0"
          @click.prevent="resendVerificationEmail()">
          {{ $t('ResendNewVerificationEmail') | lowercase }}
        </a>.
      </p>
    </vs-alert>
    <vs-alert
      color="warning"
      icon-pack="feather"
      icon="icon-alert-triangle"
      :active="!!invalidCodeError">
      <p>{{ $t('EnteredCodeInvalidAlertMsg') }}
        <a
          href="#"
          class="ml-0"
          @click.prevent="resendVerificationEmail()">
          {{ $t('ResendNewVerificationEmail') | lowercase }}
        </a>.
      </p>
    </vs-alert>
    <form @submit.prevent>
      <div class="vx-row mb-6 mt-6">
        <div class="vx-col w-full">
          <vs-input
            v-model="verificationCode"
            class="w-full required"
            :label="$t('VerificationCode')"
            :name="$t('VerificationCode')"
            v-validate="'required|digits:6'"
            data-vv-validate-on="blur|input"
            :danger="errors.has($t('VerificationCode')) || !!invalidCodeError"
            :danger-text="errors.first($t('VerificationCode')) || invalidCodeError"
            val-icon-danger="clear"
            @input="invalidCodeError = ''"/>

          <small class="font-black"><i>{{ $t('DisableAlertMsg') }}.</i></small>

        </div>
      </div>

      <base-form-footer-action-buttons
        @save="disableTwoFactorAuthentication()"
        @cancel="$emit('close')">
        <template v-slot:save>
          {{ $t('$General.Disable')}}
        </template>
      </base-form-footer-action-buttons>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

// Components
import BaseFormFooterActionButtons from '@/views/modules/_components/BaseFormFooterActionButtons.vue';

// Services
import userService from '@/api/modules/user.service';

/**
 * Component to verify an email account
 *
 * @module src/views/modules/user/user-account/user-account-profile/UserAccountProfileSecurity2FADisable
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {string} email - email account to verify
 * @vue-data {string} verificationCode - verification code entered
 * @vue-computed  {boolean} verifyCodeInputIsInvalid - indicate is code input field is invalid
 * @vue-computed  {string} invalidEnteredCodeErrorMsg - error to show for entered an invalid code
 * @vue-event  {void} resendVerificationEmail - resent and verification email
 * @vue-event  {void} disableTwoFactorAuthentication - check entered verification code
 */
export default {
  name: 'UserAccountProfileSecurity2FADisable',
  i18n: {
    messages: {
      en: {
        VerificationAlertMsg: 'We have sent you a verification email with a code. Please enter the code below. If you have not received the email, you can',
        EnteredCodeInvalidAlertMsg: 'Make sure the code is correct. If you have not received the email, you can',
        ResendNewVerificationEmail: 'Resend here',
        VerificationCode: 'Verification code',
        TwoFactorAuthDisabledNotifyTitle: 'Two-factor authentication',
        TwoFactorAuthDisabledNotifyMsg: 'Two-factor authentication disabled successfully',
        $ResendVerificationEmailNotify: {
          Title: 'Email sent',
          Msg: 'Verification email sent successfully',
        },
        DisableAlertMsg: 'Notice: If you disable the two-factor authentication, you\'ll only need your email and password or your Google account when you sign in to the GrapeSend',
      },
    },
  },
  components: {
    BaseFormFooterActionButtons,
  },
  props: {

  },
  data() {
    return {
      verificationCode: '',
      invalidCodeError: '',
    };
  },
  computed: {

  },
  methods: {
    ...mapActions({
      disableTwoFactorAuthenticationByEmailCode: 'auth/disableTwoFactorAuthenticationByEmailCode',
    }),
    async resendVerificationEmail() {
      this.$vs.loading({ type: 'radius' });
      await userService.sentEmailCodeToDisabled2FA();
      this.$vs.loading.close();

      this.verificationCode = '';
      this.invalidCodeError = '';

      this.$showSuccessActionNotification({
        title: this.$t('$ResendVerificationEmailNotify.Title'),
        text: this.$t('$ResendVerificationEmailNotify.Msg'),
      });

      await this.$validator.reset();
    },
    async disableTwoFactorAuthentication() {
      const formIsValid = await this.$validator.validate();

      if (!formIsValid) {
        return;
      }

      try {
        this.$vs.loading({ type: 'radius' });
        await this.disableTwoFactorAuthenticationByEmailCode(this.verificationCode);

        this.$showSuccessActionNotification({
          title: this.$t('TwoFactorAuthDisabledNotifyTitle'),
          text: this.$t('TwoFactorAuthDisabledNotifyMsg'),
        });

        this.$emit('close');
      } catch (err) {
        if (err.response.data.statusCode === 400 && err.response.data.invalidCode) {
          this.invalidCodeError = err.response.data.message;
        }
      } finally {
        this.$vs.loading.close();
      }
    },
  },
};
</script>
