<template>
  <div>
    <ul>
      <li>
        <b>1.</b>
        {{
          $t('$Steps2FA.Download2FAApp')
        }}
      </li>
      <li>
        <b>2.</b>
        {{
          $t('$Steps2FA.ScanQrOrEnterCode')
        }}
        <code> {{ backupCode }} </code>
      </li>
      <li>
        <b>3. {{ $t('$General.Important')}}: </b>
        <span
          v-html="$t('$Steps2FA.SaveBackupCode', {
                code: backupCode,
              })"/>
      </li>
      <li>
        <b>4.</b>
        {{
          $t('$Steps2FA.Enter2FACode')
        }}
      </li>
    </ul>

    <transition name="zoom-fade">
      <div
        v-if="qr2FASrc"
        class="qr-code mt-3">
        <img
          :src="qr2FASrc"
          alt="QR"/>
      </div>
    </transition>

    <h5 class="mb-3">
      <b>
        {{ $t('AuthenticatorCode') }}
      </b>
    </h5>

    <form @submit.prevent>
      <vs-input
        v-model="code"
        class="w-full required mb-3"
        :name="$t('AuthenticatorCode')"
        type="text"
        v-validate="'required|digits:6'"
        icon-no-border
        icon="icon icon-lock"
        icon-pack="feather"
        :danger="errors.has($t('AuthenticatorCode')) || !!invalidCodeError"
        :danger-text="errors.first($t('AuthenticatorCode')) || invalidCodeError"
        val-icon-danger="clear"
        @input="invalidCodeError = ''"/>

      <p v-html="$t('BackupCodeMsg', {
            backupCode: this.backupCode,
          })">
      </p>

      <vs-divider/>

      <div class="flex justify-end">
        <vs-button
          id="user-account-profile-enable-2FA-button"
          class="vs-con-loading__container"
          color="primary"
          @click="enableTwoFactorAuth">
          {{ $t('Enable2FA') }}
        </vs-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import UserService from '@/api/modules/user.service';

/**
 * Component to allow the user set up 2FA
 *
 * @module views/modules/user-account/UserAccountProfileSecurity2FASetup
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {string} qr2FASrc - source data for 2FA QR image
 * @vue-data {string} code - 2FA app code for enable the 2FA
 * @vue-data {string} backupCode - 2FA backup code
 * @vue-data {string} invalidCodeError - indicate the msg error for enable 2FA is invalid
 * @vue-event {void} loadSetupInfo - load initial data for setup
 * @vue-event {void} enableTwoFactorAuth - call to enable 2FA
 */
export default {
  name: 'UserAccountProfileSecurity2FASetup',
  i18n: {
    messages: {
      en: {
        Enable2FA: 'Enable two-factor authentication',
        AuthenticatorCode: 'Authenticator Code',
        BackupCodeMsg: 'If you lose your device that has your two-factor authenticator app, you can use a backup code to access your account. The backup code can only be used once, so keep it somewhere you can access even if you lose your mobile device. The backup code for this account is <code>{backupCode}</code>',
        $Steps2FA: {
          Download2FAApp: 'Download a two-factor authenticator app on your mobile device.',
          ScanQrOrEnterCode: 'Open the app, then scan QR code or manually enter this code:',
          SaveBackupCode: 'Save the backup code <code>{code}</code> somewhere safe. You can use it to access your account in the event that you lose your device.',
          Enter2FACode: 'Enter the current six-digit numerical passcode from the two-factor authenticator app to complete the setup process.',
        },
        TwoFactorAuthEnabledNotifyTitle: 'Two-factor authentication',
        TwoFactorAuthEnabledNotifyMsg: 'Two-factor authentication enabled successfully',
      },
    },
  },
  data() {
    return {
      qr2FASrc: '',
      code: '',
      backupCode: '',
      invalidCodeError: '',
    };
  },
  created() {
    this.loadSetupInfo();
  },
  methods: {
    ...mapActions({
      enableTwoFactorAuthentication: 'auth/enableTwoFactorAuthentication',
    }),
    async loadSetupInfo() {
      const resp = await UserService.getUrlQrCodeTwoFactorAuthentication();

      if (resp && resp.data) {
        this.qr2FASrc = resp.data.dataUrl;
        this.backupCode = resp.data.secret;
      }
    },
    async enableTwoFactorAuth() {
      const formIsValid = await this.$validator.validate();

      if (!formIsValid) {
        return;
      }

      this.$vs.loading({
        background: 'primary',
        color: 'white',
        container: '#user-account-profile-enable-2FA-button',
        scale: 0.45,
      });

      try {
        await this.enableTwoFactorAuthentication(this.code);

        this.$showSuccessActionNotification({
          title: this.$t('TwoFactorAuthEnabledNotifyTitle'),
          text: this.$t('TwoFactorAuthEnabledNotifyMsg'),
        });

        this.$emit('enabled');
      } catch (err) {
        if (err.response.data.statusCode === 400) {
          this.invalidCodeError = err.response.data.message;
        }
      } finally {
        this.$vs.loading.close('#user-account-profile-enable-2FA-button > .con-vs-loading');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
ul li {
  margin-bottom: 15px;
}
.qr-code {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
