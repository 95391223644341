<template>
  <div ref="connectToGoogleSection">
    <h2 class="mb-5">
      {{ $t('ConnectedAccounts') }}
    </h2>

    <div
      v-if="authUser.googleEmail"
      class="flex flex-wrap justify-between items-center mb-base">
      <div>
        <p>
          {{ $t('ConnectedWithGoogleMsg') }}
        </p>
        <p class="font-medium mb-4">{{ authUser.googleEmail }}</p>
      </div>
      <vs-button
        type="border"
        color="danger"
        @click="confirmDisconnectGoogle()">
        {{ $t('$General.Disconnect') }}
      </vs-button>
    </div>

    <div
      v-else
      class="mb-base">
      <vs-button
        color="#4285f4"
        icon-pack="fab"
        icon="fa-google"
        class="mb-1"
        @click="connectGoogleHandler()">
        {{ $t('ConnectToGoogle') }}
      </vs-button>

      <span
        v-if="googleConnectionError"
        class="text-danger">{{ googleConnectionError }}.</span>
    </div>
  </div>
</template>

<script>

import { mapActions, mapMutations, mapState } from 'vuex';
import configApi from '@/api/config';
import { emailRegex } from '@/util/util.regex';

/**
 * Component to allow the user view and update its profile connected accounts
 *
 * @module views/modules/user-account/UserAccountProfileAccounts
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-event {void} connectGoogleHandler - handler to make the connection to google
 * @vue-event {void} verifyQueryParamGoogleAccount - verify if google account was connected
 * @vue-event {void} disconnectGoogleHandler - handler to make the disconnection to google
 */
export default {
  name: 'UserAccountProfileAccounts',
  i18n: {
    messages: {
      en: {
        ConnectedAccounts: 'Connected accounts',
        ConnectToGoogle: 'Connect to Google',
        ConnectedWithGoogleMsg: 'Your account is connected to Google.',
        ConnectedToGoogleNotificationTitle: 'Connection to Google',
        ConnectedToGoogleNotificationMsg: 'Account connected successfully',
        ConnectedToGoogleNotificationErrorTitle: 'Connection to Google',
        ConnectedToGoogleNotificationErrorMsg: 'Error connecting account',
        DisconnectedToGoogleNotificationTitle: 'Connection to Google',
        DisconnectedToGoogleNotificationMsg: 'Account disconnected successfully',
        DisconnectGoogleAccountConfirmDialog: {
          Title: 'Disconnect google account?',
          Msg: `If you disconnect this account, you'll no longer be able
                to use it to log in to your GrapeSend account`,
        },
      },
    },
  },
  data() {
    return {
      googleConnectionError: '',
    };
  },
  computed: {
    ...mapState({
      authUser: (state) => state.auth.user,
      tokenJwt: (state) => state.auth.token,
    }),
  },
  created() {
    this.verifyQueryParamGoogleAccount();
  },
  mounted() {
    this.scrollIntoConnectionGoogleError();
  },
  methods: {
    ...mapActions({
      disconnectToGoogle: 'auth/disconnectToGoogle',
      refreshToken: 'auth/refreshToken',
    }),
    ...mapMutations({
      partialUpdateAuthUser: 'auth/PARTIAL_UPDATE_AUTH_USER',
    }),
    async connectGoogleHandler() {
      if (!this.tokenJwt) {
        await this.refreshToken();
      }

      window.location.href = `${configApi.url}/users/connect-google-account/${this.tokenJwt}`;
    },
    async verifyQueryParamGoogleAccount() {
      if (this.$route.query.connectedGoogleEmail
        && emailRegex.test(this.$route.query.connectedGoogleEmail)) {
        this.partialUpdateAuthUser({
          googleEmail: this.$route.query.connectedGoogleEmail,
        });

        this.$showSuccessActionNotification({
          title: this.$t('ConnectedToGoogleNotificationTitle'),
          text: this.$t('ConnectedToGoogleNotificationMsg'),
        });

        this.$route.query.connectedGoogleEmail = '';
        this.scrollIntoConnectionGoogle();
      } else if (this.$route.query.connectedGoogleError) {
        this.$showGeneralErrorNotification({
          title: this.$t('ConnectedToGoogleNotificationErrorTitle'),
          text: this.$t('ConnectedToGoogleNotificationErrorMsg'),
        });

        this.googleConnectionError = this.$route.query.messageError;
      }
    },
    confirmDisconnectGoogle() {
      this.$showConfirmDeleteDialog({
        title: this.$t('DisconnectGoogleAccountConfirmDialog.Title'),
        text: this.$t('DisconnectGoogleAccountConfirmDialog.Msg'),
        accept: () => this.disconnectGoogleHandler(),
        acceptText: this.$t('$General.Disconnect'),
        cancelText: this.$t('$General.Cancel'),
      });
    },
    async disconnectGoogleHandler() {
      this.$vs.loading({ type: 'radius' });
      await this.disconnectToGoogle();
      this.$vs.loading.close();

      this.$showSuccessActionNotification({
        title: this.$t('DisconnectedToGoogleNotificationTitle'),
        text: this.$t('DisconnectedToGoogleNotificationMsg'),
      });
    },
    scrollIntoConnectionGoogleError() {
      if (this.googleConnectionError) {
        this.scrollIntoConnectionGoogle();
      }
    },
    scrollIntoConnectionGoogle() {
      setTimeout(() => {
        const el = this.$refs.connectToGoogleSection;

        if (el) {
          el.scrollIntoView({ behavior: 'smooth' });
        }
      }, 300);
    },
  },
};
</script>
