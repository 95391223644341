<template>
  <div>
    <h2>
      {{ $t(userHasPassword ? 'ChangePassword' : 'SetPassword') }}
    </h2>

    <form @submit.prevent class="pt-5">
      <div
        v-if="userHasPassword"
        class="mb-5">
        <vs-input
          v-model="currentPassword"
          type="password"
          class="w-full required"
          :label="$t('$General.CurrentPassword')"
          :name="$t('$General.CurrentPassword')"
          v-validate="'required|password'"
          data-vv-validate-on="blur|input"
          icon-no-border
          icon="icon icon-unlock"
          icon-pack="feather"
          :danger="currentPasswordHasErrors"
          :danger-text="currentPasswordErrorMsg"
          val-icon-danger="clear"
          @input="currentPasswordError = ''"/>

        <forgot-password-link/>
      </div>

      <div class="mb-3">
        <vs-input
          ref="newPassword"
          v-model="newPassword"
          type="password"
          class="w-full required"
          :label="$t('$General.NewPassword')"
          :name="$t('$General.NewPassword')"
          v-validate="'required|password'"
          data-vv-validate-on="blur|input"
          icon-no-border
          icon="icon icon-lock"
          icon-pack="feather"
          :danger="errors.has($t('$General.NewPassword'))
              && updateProfilePasswordIsChanged"
          :danger-text="errors.first($t('$General.NewPassword'))"
          val-icon-danger="clear"/>
      </div>

      <div class="mb-3">
        <vs-input
          v-model="confirmNewPassword"
          type="password"
          class="w-full required"
          :label="$t('$General.ConfirmNewPassword')"
          :name="$t('$General.ConfirmNewPassword')"
          v-validate="'required|password|confirmed:newPassword'"
          :data-vv-as="$t('$General.NewPassword')"
          data-vv-validate-on="blur|input"
          icon-no-border
          icon="icon icon-lock"
          icon-pack="feather"
          :danger="errors.has($t('$General.ConfirmNewPassword'))
              && updateProfilePasswordIsChanged"
          :danger-text="errors.first($t('$General.ConfirmNewPassword'))"
          val-icon-danger="clear"/>
      </div>

      <div class="flex items-center justify-start">
        <vs-button
          :disabled="!updateProfilePasswordIsValid"
          @click="updateProfilePassword()">
          {{ $t('$General.Update') }}
        </vs-button>

        <vs-button
          v-show="updateProfilePasswordIsChanged"
          class="ml-3 vs-button-cancel-flat"
          color="danger"
          @click="resetPasswordModel()">
          {{ $t('$General.Cancel') }}
        </vs-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

// Components

import ForgotPasswordLink from '@/views/modules/_components/ForgotPasswordLink.vue';

/**
 * Component to allow the user view and update its profile
 *
 * @module views/modules/user-account/UserAccountProfileGeneral
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {string} [currentPassword=''] - current user password
 * @vue-data {string} [newPassword=''] - new user password
 * @vue-data {string} [confirmNewPassword=''] - confirm new user password
 * @vue-computed {boolean} updateProfilePasswordIsValid -
 * indicate if is valid or no update the profile password info
 * @vue-computed {boolean} updateProfilePasswordIsChanged -
 * indicate if is the password form was changed
 * @vue-event {void} resetPasswordModel - set the password model to the default values
 * @vue-event {void} updateProfilePassword - save the updated password
 */
export default {
  name: 'UserAccountProfilePassword',
  i18n: {
    messages: {
      en: {
        ChangePassword: 'Change password',
        SetPassword: 'Set password',
        ChangePasswordNotificationTitle: 'Change password',
        ChangePasswordNotificationMsg: 'Password changed successfully',
      },
    },
  },
  components: {
    ForgotPasswordLink,
  },
  data() {
    return {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',

      currentPasswordError: '',
    };
  },
  computed: {
    ...mapGetters({
      getUserFlag: 'auth/getUserFlag',
    }),
    currentPasswordHasErrors() {
      return (this.errors.has(this.$t('$General.CurrentPassword'))
        || !!this.currentPasswordError)
        && this.updateProfilePasswordIsChanged;
    },
    currentPasswordErrorMsg() {
      return this.errors.first(this.$t('$General.CurrentPassword')) || this.currentPasswordError;
    },
    userHasPassword() {
      return this.getUserFlag('hasPassword');
    },
    updateProfilePasswordIsValid() {
      return !this.errors.any()
        && ((this.currentPassword !== ''
          && !this.currentPasswordError)
          || !this.userHasPassword)
        && this.newPassword !== ''
        && this.confirmNewPassword !== '';
    },
    updateProfilePasswordIsChanged() {
      return Object.keys(this.validationFields).length > 0
        ? Object.keys(this.validationFields).some(
          (key) => this.validationFields[key].changed,
        )
        : false;
    },
  },
  methods: {
    ...mapActions({
      updatePassword: 'auth/updatePassword',
    }),
    resetPasswordModel() {
      this.currentPassword = '';
      this.newPassword = '';
      this.confirmNewPassword = '';

      this.$validator.reset();
    },
    async updateProfilePassword() {
      if (this.errors.any()) {
        return;
      }

      this.$vs.loading({ type: 'radius' });

      const payload = {
        currentPassword: this.currentPassword,
        newPassword: this.newPassword,
        confirmNewPassword: this.confirmNewPassword,
      };

      if (!this.userHasPassword) {
        delete payload.currentPassword;
      }

      try {
        await this.updatePassword(payload);
        this.resetPasswordModel();

        this.$showSuccessActionNotification({
          title: this.$t('ChangePasswordNotificationTitle'),
          text: this.$t('ChangePasswordNotificationMsg'),
        });
      } catch (err) {
        if (err.response && err.response.data && err.response.data.currentPassword) {
          this.currentPasswordError = err.response.data.message;
        } else {
          throw err;
        }
      } finally {
        this.$vs.loading.close();
      }
    },
  },
};
</script>
